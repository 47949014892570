<!--
 * @Descripttion: 营收数据报表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-09-07 15:05:39
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-29 16:15:38
-->
<template>
    <div class="revenueDataReport">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">营收数据报表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <commonSelect
                    @changeDate="changeDate"
                    @clickTagDate="clickTagDate"
                ></commonSelect>
            </div>
        </div>
        <div class="case-wrap">
            <div class="alex-wrap">
                <div class="select">
                    <el-select filterable clear class="sele" clearable  @change="selectChangeEc" 
                        v-model="ec_id" placeholder="请选择企业筛选">
                        <el-option
                            v-for="item in ecOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select filterable clear class="sele" clearable  style="margin-left: 10px" v-if="ec_id!=''"
                        @change="selectChangeEp" v-model="ep_id" placeholder="请选择品牌筛选">
                        <el-option
                            v-for="item in epOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-wrap">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button @click="clickSearch" type="primary">搜索</el-button>
                </div>
            </div>
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">营收分析</div>
            </div>
            <div class="alex-content">
                <div class="ac-left" v-loading="loading1">
                    <div class="count-wrap">
                        <div class="item">
                            <div class="number">{{dataInfo.price}}</div>
                            <div class="tit">订单总金额</div>
                        </div>
                        <div class="item">
                            <div class="number">{{dataInfo.pay_real_price}}</div>
                            <div class="tit">实付总金额</div>
                        </div>
                        <div class="item">
                            <div class="number">{{dataInfo.received_price}}</div>
                            <div class="tit">实收总金额</div>
                        </div>
                        <div class="item">
                            <div class="number">{{dataInfo.service_price}}</div>
                            <div class="tit">品牌服务总金额</div>
                        </div>
                    </div>
                    <div class="echart">
                        <div id="revenueGrades" style="width: 100%;height: 350px;"></div>
                    </div>
                </div>
                <div class="ac-right">
                    <div v-loading="loading2">
                        <div id="revenuePieGrades" style="width: 100%;height: 350px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="case-wrap">
            <div class="title-box">
                <div class="title-wrap">
                    <div class="tag"></div>
                    <div class="title">营收列表</div>
                </div>
                <div class="select-wrap">
                    <div class="select-item">
                        <el-select filterable clear clearable  @change="selectChangezf" 
                            v-model="zf_id" placeholder="全部">
                            <el-option
                                v-for="item in zfOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-item">
                        <el-select clearable v-model="sel_paytype" @change="selChange" placeholder="请选择支付方式">
                            <el-option v-for="item in paytypeOptions" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-item">
                        <el-select clearable v-model="sel_promotion" @change="selChange" placeholder="请选是否分账">
                            <el-option v-for="item in promotionOptions" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    
                </div>
                <div class="title-btn">
                    <el-button type="primary" @click="batchAudit">批量确定支付</el-button>
                </div>
            </div>
            <div class="table-wrap">
                <el-table :data="tableData" border v-loading="loading" height="76vh"
                    @selection-change="handleSelectionChange">
                    <el-table-column align="center" type="selection" width="55"></el-table-column>
                    <el-table-column fixed="left" align="center" prop="id" label="ID" width="60"></el-table-column>
                    <el-table-column align="center" prop="enterprise.name" label="企业名称" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="enterprise_brand.name" label="品牌名称" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="name" label="姓名" width="120" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="mobile" label="电话" width="120" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="address" label="地址" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="price" label="订单金额" width="110"></el-table-column>
                    <el-table-column align="center" prop="pay_real_price" label="实付金额" width="100"></el-table-column>
                    <el-table-column align="center" prop="service_price" label="服务金额" width="100"></el-table-column>
                    <el-table-column align="center" prop="wx_deduction" label="微信扣款" width="100"></el-table-column>
                    <el-table-column align="center" prop="promotion_split_price" label="分账金额" width="100"></el-table-column>
                    <el-table-column align="center" prop="received_price" label="实收金额" width="100"></el-table-column>
                    <el-table-column align="center" prop="title" label="标题" width="270" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="pay_way" label="支付方式" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.pay_way == 'wxpay'">微信支付</div>
                            <div v-else-if="scope.row.pay_way == 'alipay'">支付宝支付</div>
                            <div v-else>{{scope.row.pay_way}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="state" label="订单状态" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state == 0">未支付</div>
                            <div v-if="scope.row.state == 1">已支付</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="remark" label="支付备注" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="pay_time" label="支付时间" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="sn" label="订单序列号" width="260" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="pay_sn" label="支付流水线号" width="260" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="pay_openid" label="微信Openid" width="100" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="pay_mch_id" label="交易商户号" width="150" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" fixed="right" label="操作" width="80">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip v-if="scope.row.state == 0" class="item" effect="dark" content="确定支付" placement="top">
                                    <i @click="clickState(scope.row)" class="iconfont el-icon-circle-check"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="page"
                        :page-size="100"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import {revenuePieEcharts} from "@/echarts/pieEchartData";
import {revenueLineEcharts} from "@/echarts/lineEchartData";
export default {
    components: {
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'revenueDataReport',
            dateType: '',
            startDate: '',
            endDate: '',
            ep_id: '',
            ec_id: '',
            zf_id: '',
            keyword: '',
            page: 1,
            total: 0,
            epOptions: [],
            ecOptions: [],
            tableData: [],
            sel_paytype: '',
            paytypeOptions: [
                {
                    value: 1,
                    label: '微信支付'
                }, {
                    value: 2,
                    label: '总部转账'
                }
            ],
            
            sel_promotion: '',
            promotionOptions: [
                {
                    value: 1,
                    label: '分账订单'
                }, {
                    value: 2,
                    label: '非分账订单'
                }
            ],

            zfOptions: [{
                label: '全部',
                value: ''
            },{
                label: '已支付',
                value: 1
            },{
                label: '未支付',
                value: 0
            }],
            loading: false,
            loading1: false,
            loading2: false,
            dataInfo: {},
            idsList: []
        }
    },
    mounted () {
        this.getrevenueLineChart();
        this.getrevenuePieChart();
        this.getrevenueData();
        this.getrevenueDataInfo();
        this.getEcConditions();
    },
    methods: {
        // 批量审核
        batchAudit(){
            if(this.idsList.length <= 0){
                this.$message.warning('请选择要确定支付的数据')
            }else{
                this.$confirm('是否批量确定支付操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let list = [];
                    this.idsList.forEach(v=>{
                        list.push(v.id)
                    })
                    let params = {
                        ids: '['+list+']'
                    }
                    common.connect('/customerservicev1/data_report/batchRevenueDataReportConfirmPayment',params,(res)=>{
                        this.page = 1;
                        this.getrevenueData();
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        },
        // 多选
        handleSelectionChange(val){
            this.idsList = val;
        },
        selectChangezf(){
            this.page = 1;
            this.getrevenueData();
        },
        // 
        clickState(row){
            this.$confirm('是否确定已支付?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/data_report/revenueDataReportConfirmPayment",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getrevenueData();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 删除
        clickDel(row){  
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/data_report/deleteRevenueData",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getrevenueData();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        // 表格数据
        getrevenueData(){
            let params = {
                page: this.page,
                ep_id: this.ep_id,
                e_id: this.ec_id,
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                state: this.zf_id,
                pay_way: this.sel_paytype,
                promotion_state: this.sel_promotion
            }
            this.loading = true;
            common.connect("/customerservicev1/data_report/revenueDataReportIndex",params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        // 数据汇总
        getrevenueDataInfo(){
            let params = {
                ep_id: this.ep_id,
                e_id: this.ec_id,
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            common.connect("/customerservicev1/data_report/revenueDataReport",params,(res)=>{
                this.dataInfo = res.data;
            });
        },
        // 折线图数据
        getrevenueLineChart(){
            let params = {
                ep_id: this.ep_id,
                e_id: this.ec_id,
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading1 = true;
            common.connect("/customerservicev1/data_report/revenueDataReportLineChart",params,(res)=>{
                revenueLineEcharts('revenueGrades',res.data);
                this.loading1 = false;
            });
        },
        // 饼图数据
        getrevenuePieChart(){
            let params = {
                ep_id: this.ep_id,
                e_id: this.ec_id,
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading2 = true;
            common.connect("/customerservicev1/data_report/revenueDataReportPicChart",params,(res)=>{
                revenuePieEcharts('revenuePieGrades',res.data);
                this.loading2 = false;
            });
        },
        // 获取品牌数据
        getEpConditions(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{e_id:this.ec_id},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.ep_id,
                    label: v.name
                }
                })
                this.epOptions = resultSecond;
            });
        },
        // 获取企业数据
        getEcConditions(){
            common.connect("/customerservicev1/enterprise/eConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.e_id,
                    label: v.name
                }
                })
                this.ecOptions = resultSecond;
            });
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.page = 1;
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getrevenueLineChart();
            this.getrevenuePieChart();
            this.getrevenueData();
            this.getrevenueDataInfo();
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.page = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getrevenueLineChart();
                this.getrevenuePieChart();
                this.getrevenueData();
                this.getrevenueDataInfo();
            }
        },
        // 搜索
        clickSearch(){
            this.page = 1;
            this.getrevenueLineChart();
            this.getrevenuePieChart();
            this.getrevenueData();
            this.getrevenueDataInfo();
        },
        // 企业选择筛选
        selectChangeEc(){
            this.page = 1;
            this.ep_id = '';
            this.getrevenueLineChart();
            this.getrevenuePieChart();
            this.getrevenueData();
            this.getrevenueDataInfo();
            this.getEpConditions();
        },
        // 品牌选择筛选
        selectChangeEp(){
            this.page = 1;
            this.getrevenueLineChart();
            this.getrevenuePieChart();
            this.getrevenueData();
            this.getrevenueDataInfo();
        },
        //筛选
        selChange() {
            this.page = 1;
            this.getrevenueData();
        },
        handleCurrentChange(val){
            this.page = val;
            this.getrevenueData();
        }
    },
}
</script>

<style lang='scss'>
.revenueDataReport{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-box{
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            .title-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: 5px 0;
                width: 100px;
                .tag{
                    width: 3px;
                    height: 15px;
                    background: #409EFF;
                    border-radius: 10px;
                }
                .title{
                    font-size: 16px;
                    font-weight: bold;
                    padding-left: 10px;
                }
            }
            .select-wrap{
                width: calc(100% - 100px);
                display: flex; 
                justify-content: flex-start;
                align-items: center;
                .select-item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-left: 20px;
                }
            }
            .title-btn{
                margin-right: 10px;
            }
        }
        
        .alex-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
        }
        .search-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 20px;
            .el-input{
                width: 200px;
                margin-right: 10px;
            }
        }
        .alex-content{
            display: flex;
            justify-content: space-between;
            .ac-left{
                flex: 3;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .count-wrap{
                    width: 200px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .item{
                        width: 200px;
                        border: 1px solid #ddd;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px 0;
                        margin: 5px 0;
                        .number{
                            font-size: 22px;
                            color: #000;
                            font-weight: bold;
                        }
                        .tit{
                            margin-left: 10px;
                            font-size: 15px;
                        }
                    }
                }
                .echart{
                    width: calc(100% - 220px);
                }
            }
            .ac-right{
                flex: 1;
            }
        }
        .table-wrap{
            margin-top: 15px;
            .iconfont{
                cursor: pointer;
            }
            .operation-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 8px;
                    cursor: pointer;
                }
                .el-icon-sort{
                    transform: rotate(90deg);
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 5px;
            }
        }
    }
}
</style>
